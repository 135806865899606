import React from "react"

function ContentfulIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
      <style>
        {
          ".prefix__st0{fill:#fff}.prefix__st1{fill:#ffd75f}.prefix__st2{fill:#4fb5e1}.prefix__st3{fill:#f05751}.prefix__st4{fill:#0681b6}.prefix__st5{fill:#cd4739}"
        }
      </style>
      <g id="prefix__Mark_w_x2F__shadow__x28_wht_x29_">
        <circle cx={-226.8} cy={197.5} r={184.7} className="prefix__st0" />
        <path
          d="M-260.9 239.7c-10.9-11-17.7-26-17.7-42.7 0-16.7 6.8-31.7 17.7-42.7 9.3-9.3 9.3-24.3 0-33.5-9.3-9.3-24.3-9.3-33.6 0-19.5 19.5-31.6 46.4-31.6 76.2 0 29.8 12.1 56.7 31.6 76.2 9.3 9.3 24.3 9.3 33.6 0 9.3-9.2 9.3-24.2 0-33.5z"
          className="prefix__st1"
        />
        <path
          d="M-260.9 154.4c11-10.9 26.1-17.7 42.7-17.7 16.7 0 31.8 6.8 42.7 17.7 9.3 9.3 24.3 9.3 33.6 0s9.3-24.3 0-33.5c-19.5-19.5-46.5-31.6-76.3-31.6-29.8 0-56.8 12.1-76.3 31.6-9.3 9.3-9.3 24.3 0 33.5 9.3 9.3 24.4 9.3 33.6 0z"
          className="prefix__st2"
        />
        <path
          d="M-175.4 239.7c-11 10.9-26.1 17.7-42.7 17.7-16.7 0-31.8-6.8-42.7-17.7-9.3-9.3-24.3-9.3-33.6 0s-9.3 24.3 0 33.5c19.5 19.5 46.5 31.6 76.3 31.6 29.8 0 56.8-12.1 76.3-31.6 9.3-9.3 9.3-24.3 0-33.5s-24.3-9.2-33.6 0z"
          className="prefix__st3"
        />
        <path
          d="M-260.9 154.4c-9.3 9.3-24.3 9.3-33.6 0s-9.3-24.3 0-33.5c9.3-9.3 24.3-9.3 33.6 0 9.3 9.2 9.3 24.2 0 33.5z"
          className="prefix__st4"
        />
        <path
          d="M-260.9 273.3c-9.3 9.3-24.3 9.3-33.6 0-9.3-9.2-9.3-24.3 0-33.5 9.3-9.3 24.3-9.3 33.6 0 9.3 9.2 9.3 24.2 0 33.5z"
          className="prefix__st5"
        />
      </g>
      <g id="prefix__Mark_w_x2F_o_shadow__x28_wht_x29_">
        <circle cx={193.2} cy={197.5} r={184.7} className="prefix__st0" />
        <path
          d="M159.1 239.7c-10.9-11-17.7-26-17.7-42.7 0-16.7 6.8-31.7 17.7-42.7 9.3-9.3 9.3-24.3 0-33.5-9.3-9.3-24.3-9.3-33.6 0C106 140.4 94 167.3 94 197.1c0 29.8 12.1 56.7 31.6 76.2 9.3 9.3 24.3 9.3 33.6 0 9.2-9.3 9.2-24.3-.1-33.6z"
          className="prefix__st1"
        />
        <path
          d="M159.1 154.4c11-10.9 26.1-17.7 42.7-17.7 16.7 0 31.8 6.8 42.7 17.7 9.3 9.3 24.3 9.3 33.6 0s9.3-24.3 0-33.5c-19.5-19.5-46.5-31.6-76.3-31.6-29.8 0-56.8 12.1-76.3 31.6-9.3 9.3-9.3 24.3 0 33.5 9.3 9.3 24.4 9.3 33.6 0z"
          className="prefix__st2"
        />
        <path
          d="M244.6 239.7c-11 10.9-26.1 17.7-42.7 17.7-16.7 0-31.8-6.8-42.7-17.7-9.3-9.3-24.3-9.3-33.6 0s-9.3 24.3 0 33.5c19.5 19.5 46.5 31.6 76.3 31.6 29.8 0 56.8-12.1 76.3-31.6 9.3-9.3 9.3-24.3 0-33.5s-24.3-9.2-33.6 0z"
          className="prefix__st3"
        />
        <path
          d="M159.1 154.4c-9.3 9.3-24.3 9.3-33.6 0s-9.3-24.3 0-33.5c9.3-9.3 24.3-9.3 33.6 0 9.3 9.2 9.3 24.2 0 33.5z"
          className="prefix__st4"
        />
        <path
          d="M159.1 273.3c-9.3 9.3-24.3 9.3-33.6 0-9.3-9.2-9.3-24.3 0-33.5 9.3-9.3 24.3-9.3 33.6 0 9.3 9.2 9.3 24.2 0 33.5z"
          className="prefix__st5"
        />
      </g>
    </svg>
  )
}

export default ContentfulIcon
