import React from "react"

function TypescriptIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      fill="none"
      viewBox="0 0 27 26"
      {...props}
    >
      <rect fill="white" height="15" x="5" width="20" y="10" />
      <path
        fill="#2f73c0"
        fillRule="evenodd"
        d="M.986 0H25.31a.98.98 0 01.986.975v24.05a.98.98 0 01-.986.975H.986A.98.98 0 010 25.025V.975A.98.98 0 01.986 0zm13.632 13.832V11.7H5.258v2.132H8.6v9.495h2.66v-9.495zm1.06 9.244c.429.216.936.379 1.522.487.585.108 1.203.162 1.852.162.632 0 1.233-.06 1.802-.178.569-.12 1.068-.315 1.497-.588s.768-.63 1.019-1.07.375-.986.375-1.635c0-.47-.072-.882-.215-1.236a2.883 2.883 0 00-.618-.945c-.27-.275-.593-.523-.97-.742s-.8-.425-1.274-.62a16.19 16.19 0 01-.932-.41 4.74 4.74 0 01-.701-.405 1.783 1.783 0 01-.445-.438.91.91 0 01-.157-.523c0-.178.047-.339.14-.482.094-.143.225-.266.396-.37s.38-.182.627-.238c.247-.057.522-.085.825-.085a5.608 5.608 0 011.435.199c.247.067.488.152.722.255.233.102.45.221.647.356v-2.424a6.522 6.522 0 00-1.316-.337 10.893 10.893 0 00-1.637-.109 7.73 7.73 0 00-1.781.199c-.561.132-1.055.339-1.481.62s-.763.64-1.01 1.074c-.248.435-.372.956-.372 1.561 0 .773.227 1.433.68 1.979.455.546 1.143 1.008 2.067 1.386.363.146.701.29 1.015.43.313.14.584.287.812.438.228.151.408.316.54.494a.995.995 0 01.075 1.075c-.083.143-.208.267-.376.373s-.376.188-.627.247c-.25.06-.543.09-.878.09a5.089 5.089 0 01-1.7-.297 5.094 5.094 0 01-1.558-.887z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default TypescriptIcon
