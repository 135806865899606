import React from "react"

function NodeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 256 282"
      {...props}
    >
      <g fill="#8CC84B">
        <path d="M117 4c6-4 16-4 22 0l105 59c7 4 11 11 11 18v119c0 8-4 16-11 19l-105 60c-7 4-16 3-23-1l-32-18-6-5 6-2 12-6h4l26 15c2 2 4 0 6-1l103-58c1 0 2-2 1-3V82c1-1 0-3-2-4L130 20a4 4 0 00-4 0L22 78c-2 1-3 3-2 4v118a3 3 0 001 3l28 16c5 3 12 4 18 2 5-2 8-7 8-12V92c0-2 2-3 3-3h14c2 0 3 2 3 3v118c0 11-5 22-14 27-12 6-27 5-39-1l-30-17c-7-3-11-11-11-19V81c0-7 4-15 11-18L117 4z"></path>
        <path d="M147 86c15-1 31-1 45 7 11 6 17 18 17 29-1 2-2 3-4 3h-13c-2 0-3-2-3-4-1-5-4-11-10-13-8-5-17-4-26-4-7 0-14 1-19 4-4 3-6 9-4 14 1 3 5 4 8 5 19 5 38 5 56 11 8 3 15 8 17 15 4 11 2 23-5 32-6 7-15 10-24 12-11 3-24 3-35 2s-23-4-32-12c-7-6-11-16-10-26a658 658 0 0117-3l3 4c0 5 2 11 7 14 9 6 20 5 31 5 8 0 18 0 25-6 4-3 5-8 4-13-1-4-5-6-9-7-18-6-38-4-56-10-7-3-14-7-17-15-4-11-2-24 6-32s20-11 31-12z"></path>
      </g>
    </svg>
  )
}

export default NodeIcon
