import React from "react"

function GsapIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 315 359" {...props}>
      <path
        fill="#010101"
        d="M48 156c-10 4-38 26-43 33-6 6-7-4-1-21 4-13 15-32 35-48a365 365 0 01128-56c3-1-3 5-4 8-6 15-111 82-111 82zm149 186c2 11 0 12-11 14-12 1-20-3-23-6s-4-9-1-15c2-4 4-5 5-9 1-5 2-6 3-7l1-13 1-37c1-8 36 4 36 4l-10 26c-3 5-2 15-2 17l-2 6s2 5 1 9c-1 2 2 10 2 11zm14-311c-2-8 0-23 1-26 5-6 32-7 38 1 2 4 2 12 2 18l-2 21c-1 3-30 7-37-1l-2-13zm59 292c13 9 22 10 29 13 6 4 4 10-6 12-9 1-22-2-31-2s-29 1-34-2c-6-5-4-13-4-13 2-4 6-14 5-22s-3-13-6-20c-3-8-6-26-3-28s2 0 6 1l16 2 19-1c3-1 6-2 5 5-1 6-4 18-4 27v21l8 7z"
      ></path>
      <path
        fill="#8ac640"
        d="M227 51c-23 20-66 25-130 50-17 6-61 25-71 45 35-21 48 8 51 37 3 27 17 41 52 40 36 0 47 21 57 45 19-94 50-112 70-129 42-34-10-95-29-88z"
      ></path>
      <path
        fill="#8ac640"
        d="M227 51c-54 19-107 24-170 62-15 9-46 30-52 70 23-25 76-57 140-77 33-11 57-17 85-28l-3-27z"
      ></path>
      <path
        fill="#010101"
        d="M295 163s-3 13-11 17c0 0-6 2-17-4-4-2-6-8-6-10 0-5 3-7 3-7s4-6 8-5l12-1c2-3 12 3 11 10zM212 35l-2 17-11 3c9 25 60 25 67 13-3-3-10-6-13-7-3-2-3-1-3-7l-1-19c0-3-32-3-37 0z"
      ></path>
      <path
        fill="#f0efef"
        d="M214 35v11l-1 7c0 1 7 6 9 17 10 21 32 11 42 0l-15-8c-3-1-2-3-2-9l-1-17c-1-3-27-4-32-1z"
      ></path>
      <g fill="#010101">
        <path d="M212 35v8l8 8c2 2 7 2 11 2l8-1 11-8-1-9c0-3-32-3-37 0z"></path>
        <path d="M231 50h-8l-3-2-4-3c-3-2-3-4-3-5 1-12-1-23-1-23s-1-6 6-7l13-2 12 2c8 1 7 7 7 7s-2 11-1 23c0 1 0 3-3 5l-8 5h-7z"></path>
        <path d="M231 3c-14 0-16 4-20 8-1 1 0 7 1 20h2l-1-8v-5c0-1-2-3 2-5 3-2 9 1 16 1s13-3 16-1c4 2 2 4 2 5v5l-1 8h2c1-13 2-19 1-20-3-4-7-8-20-8zm64 161s-3 12-11 16l-3-4-2-1-2-3-2-1-2-2-2-1c-1-1-10-3-10-5l3-5s4-6 8-6c3 1 7 1 9-1s15 6 14 13z"></path>
        <path d="M269 73s13-3 22 6c8 8 8 19 5 25 2 3 6 10 3 19 14 12 8 29-4 41 0 0-3-11-14-11 0-5-11-8-6-18-8-2-7-8-9-14-1-7 3-48 3-48z"></path>
      </g>
      <path
        fill="#f0efef"
        d="M264 79s15-7 24 1c9 9 6 18 4 25 2 2 7 10 4 19-1 2-5 2-8 7 8-5 13-3 15 3 2 9-4 20-9 26l-1-1c0 3-1 9-8 16-3 3-5 3-8 2-5-1-6-3-12-6-3-2-1-4-1-6l1-2 3-4s3-4 7-4l9-1c0-8-8-8-5-20 0-1 1-2 4-2-5-1-7-1-11-5-2-2-4-5-4-8-2-7-4-40-4-40z"
      ></path>
      <path
        fill="#010101"
        d="M221 66c-1-1-7-9-19-13-4-1-6 2-9 4-1 0-6-2-8-1l-7 4h-7c-3 1-7 3-11 8-2 9 44 2 44 2z"
      ></path>
      <path
        fill="#8ac640"
        d="M222 66c-7-2-6-9-20-11-3 0-5 2-7 4l3 4c-2-2-5-5-9-5-3-1-5 1-8 3l2 3c-3-2-5-3-9-1-2 1-7 3-11 8-3 9 41-1 41-1z"
      ></path>
      <path
        fill="#010101"
        d="M260 70v-5l-2-1v-1s3-2 5-1c3 1 5 5 5 5 4-2 5 0 7 2 5-1 5 1 8 6h-10c-7 1-13-6-13-5z"
      ></path>
      <path
        fill="#8ac640"
        d="M259 69l1-2c2-1 5-2 7 2 4-2 5 0 7 3 6-3 6 0 7 2h-8c-7 1-14-6-14-5z"
      ></path>
      <path
        fill="#010101"
        d="M190 64s12 4 21 3c10-1 27-2 36 4 0 0 9-4 17-1 9 2 25 17 20 32l-6 4s2 7-1 12c-3 6-9 16-10 31l-1 10c-16-1-40-6-64-6l1-7-3-15c-7-5-16-11-22-22-7-10 12-45 12-45zm79 109s12 46-3 65c0 0 3 8-3 18l-2 12c-3 17-7 24-8 51h-13s-1-20-5-30c-3-6-12-14-12-22 1-6 2-11 12-20 1-11-11-23-9-67z"
      ></path>
      <path
        fill="#f0efef"
        d="M265 172c3 20 11 51-3 66a31 31 0 010 12l-2 8c-2 5-3 12-3 16-3 17-3 18-4 45h-13s-1-20-5-30c-3-6-8-11-8-19-1-6 4-18 14-18-1-25-12-20-15-71zM190 64l9 1h15c10-1 24-2 33 4 0 0 5-2 11-2 2 1 4 3 7 3 9 3 18 18 14 31l-7 1c1 3 1 9-2 16s-8 16-7 33l-52-3-3-19 6-5c-6 1-8 0-12-2s-10-8-13-12c-1-1-3 6-8 6-2-3 6-10 4-13-5-10 5-39 5-39z"
      ></path>
      <path
        fill="#010101"
        d="M267 155v9l2 11c-2 4-12 13-30 16-17 3-41-24-41-24a188 188 0 015-16c2-3 64 4 64 4z"
      ></path>
      <path
        fill="#f0efef"
        d="M262 155l1 12 3 14c0 12-15 10-28 10-18 4-33-24-33-24l4-14 1-7 52 2v7z"
      ></path>
      <path
        fill="#010101"
        d="M242 182s-10-24-43-17c-7 22-18 47-11 74-7 9-15 14-15 31l1 20 24 1s8-10 11-21c1-3 2-9 1-15 1-1 4-4 5-9 0 0 11-7 18-21 6-14 5-25 9-43z"
      ></path>
      <path
        fill="#f0efef"
        d="M242 182c0-3-8-25-38-18-18 47-12 67-9 75-12 16-17 19-15 50h16c7-12 9-16 9-31l-4 3c2-6 5-8 8-12l2-7c14-9 19-21 23-34 2-8 5-22 8-26z"
      ></path>
      <g fill="#010101">
        <path d="M192 149l11 2c4 1 4 2 6 4s6 5 6 9l-3 3-6 6-2 2-3 2-6 1s-5 0-9-4l1-2-6-6v-8c0-2 8-7 11-9zm5 198v5s-2 2-16 2-17-4-19-7v-5l3-4c4 1 20 7 30 8l2 1zm30-10v4c2 2 8 3 20 3 17-1 32 5 52 2v-4h-2l-68-6z"></path>
        <path d="M242 344h5c11-1 21 2 32 2 6 0 14-1 13-5l-58-5c-3 3-1 7 8 8zm-67-86c2 1 15 9 35 8-1 3-1 11-9 24-5 7-9 18-10 33 1 3 2 6 0 10l6 11c1 3 2 7-9 7-11 1-28-1-26-9 1-5 5-9 5-11 1-2 1-8 4-11 3-10 2-20 1-29-1-10-1-25 3-33zm19-166c15-19 2-35-21-30-9 2-19 8-19 20-3 0-10 1-14 13-2 10-4 12-8 17-3 3 0 11 2 16 8 17 31 19 47 32 5-1 10-7 12-11-13-16-10-22-16-30 6-2 19-10 17-27z"></path>
      </g>
      <path
        fill="#f0efef"
        d="M191 91l3-5 3 3c5-9 7-22 0-23l-6-1c-4-2-8-2-14-1-9 2-18 8-18 18 0 0 6 2 8 10-3-4-8-9-15-5-6 4-3 14-12 24-3 4-2 8 1 16 5 13 30 20 44 32l7-7c-9-10-13-19-16-25-3-8-6-12-14-18l9 4c1 1 4 2 7 1 8-3 13-15 13-23z"
      ></path>
      <path
        fill="#010101"
        d="M280 94c-1 8-6 4-9 2-7-2-13-4-20-4s-16 1-24 3c-11 3-14 3-19 0l-14-9 2 5c3 3 9 7 17 10 4 1 7 0 10-1 18-4 37-5 54 5l4-3c2-3-1-8-1-8z"
      ></path>
      <path fill="#0a1825" d="M187 172l6 3-2-1-4-2z"></path>
      <path
        fill="#f0efef"
        d="M246 10c0-2-5-5-15-5-12 0-15 4-15 5l-1 10v8l2 10c0 4 1 7 3 11l3 1h15c3-1 6-4 6-8l2-10 1-10-1-12z"
      ></path>
      <path
        fill="#010101"
        d="M225 262s11 5 37 1c0 0-7 37-4 56 0 0 4 5 29 15 0 0 12 2 11 8 0 0 1 1-13 1s-27-3-36-3-19 1-21-2 1-9 3-13c3-3 2-6 2-13 0-18-14-37-8-50z"
      ></path>
      <path
        fill="#8ac640"
        d="M223 265c-2 2 0 10 2 18 2 7 6 12 7 24s2 14-2 21-4 14 8 14 18 0 30 2c13 1 17 2 24 1s8-3 5-5-15-5-20-9c-5-3-19-9-19-11v-32l3-18c0-2 3-5-1-4l-26 2c-10-1-11-3-11-3z"
      ></path>
      <path
        fill="#010101"
        d="M229 328c4 2 9 3 13 3 0 0-2 7 1 11 2 4-17 2-17 2v-15zm54 18c4-1 6-5 3-8-3-4-5-4-1-5 3 0 17 8 17 8l-7 7zm-61-73a83 83 0 0039-1c6-2 3 2 3 2l-3 3s-8 3-20 3c-15 0-19-2-19-2zm0 10c5 2 12 3 18 2 6 0 15 0 20-2 3-1 1 3 1 3l-1 1s-8 3-20 3l-17-1z"
        opacity="0.3"
      ></path>
      <path
        fill="none"
        d="M225 262s17 6 37 2c0 0-7 36-4 55 0 0 4 5 29 14 0 0 12 3 11 9 0 0 1 1-13 1l-36-3c-9 0-19 0-21-2-2-3 1-9 3-13 3-4 2-6 2-13 0-18-14-37-8-50z"
      ></path>
      <path
        fill="none"
        d="M225 262s17 6 37 2c0 0-7 36-4 55 0 0 4 5 29 14 0 0 12 3 11 9 0 0 1 1-13 1-14-1-27-5-36-3-10 2-19 1-21-2-1-3 1-9 3-13 3-4 2-6 2-13 0-18-14-37-8-50z"
      ></path>
      <path
        fill="#010101"
        d="M209 141c0 1 16 9 25 11 8 1 27 0 29-1v3s-22 3-38-1c-17-5-15-6-15-6z"
      ></path>
      <path
        fill="#f0efef"
        d="M175 314c2 2 11 4 17 3 1-1 0-14 2-21 3-8 9-16 11-31l-23-5c-7 20-5 37-7 54zm14 13l2 1c1-2-1-5-1-7v-1l-14-1c-1 3-3 3-4 8-1 3 3 0 6 0-6 4-12 8-13 12-1 6 1 8 7 11l16 1c6-1 7-4 5-7l-4-17z"
      ></path>
      <path
        fill="#010101"
        d="M191 63c6 4 16 3 19 2l10-1v1s-3 2-9 2c-7 0-15 0-19-2-5-2-1-2-1-2zm69 4c1 0 9 3 13 7 3 3 1 3 0 2l-9-6-6-3h2z"
      ></path>
      <path
        fill="#f0efef"
        d="M190 151c6 0 12 1 16 5 2 2 5 4 5 6l-3 4-3 3-4 3-2 2c-2 1-4 0-6-1s0-1-2-3c-6-3-9-6-7-14l6-5z"
      ></path>
      <path
        fill="#010101"
        d="M138 104c-19 8-65 31-78 38 11 7 16 24 17 41l1 4 1 3v1c12-33 55-67 56-70 1-1-1-6 2-11 4-7 11-10 1-6zm43 154c10-19 10-36 10-57 0-9 6-22 3-18-12 14-43 39-65 41 31-1 43 14 52 34z"
        opacity="0.4"
      ></path>
      <path
        fill="#010101"
        d="M42 159c11-8 71-40 86-44 14-5 10-11 10-11s-59 26-94 47"
      ></path>
      <path
        fill="#010101"
        d="M213 101c-5-2-9 3-7 9 1 5 12 11 11 30 0 16-4 22-4 22l-7-9s-2-22-4-26l-15-12c-3-3 4-7 4-13-1-5 3-16 3-16zm28 86c0 16 3 38 5 49 1 11 3 12 0 17-4 4-8 12-8 12l-14-1s11-15 12-17l-6-23zM214 45c3 6 6 9 12 11 3 1 16 3 21-3 2-3 1-10 1-10l-12 10-16-3-1-5z"
        opacity="0.4"
      ></path>
      <path
        fill="#8ac640"
        stroke="#010101"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
        d="M215 59c5 3 19 7 22 7 4 1 10-4 13-5s8-2 14 3c1 1-1 4-1 4l-3-1-6-2c-10 2-12 5-20 3s-12-4-20-3"
      ></path>
      <path
        fill="#8ac640"
        d="M213 59l24 7c4 1 9-3 12-5 5-3 12 0 15 3l3 5h-3l-4-2-6-2c-10 1-12 5-21 3s-11-4-20-3"
      ></path>
      <path
        fill="#010101"
        d="M182 250v-1c-3 0-7-2-9-5-8-13-21-21-44-20-35 0-49-14-52-41-1-15-5-29-14-38l-4 3c9 9 12 22 14 40 3 27 25 40 51 40 27 1 36 6 46 20 3 4 7 5 10 6z"
      ></path>
      <path
        fill="#010101"
        d="M276 119c1 3 3 9 6 12v1h-10l-2-4z"
        opacity="0.4"
      ></path>
    </svg>
  )
}

export default GsapIcon
