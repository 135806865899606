import React from "react"

function ApolloIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="27 29 1174 1144"
      {...props}
    >
      <path d="M599 30l-18 1A570 570 0 0070 729a574 574 0 00889 339 582 582 0 00240-430c2-20 1-71-2-92-4-42-15-97-22-109-2-4-10-10-15-12l-9-1c-15 0-28 13-28 27l4 21a509 509 0 01-247 579 517 517 0 01-766-491 502 502 0 0185-243 526 526 0 01295-213 511 511 0 01462 101l4 3-1 4c-8 21-2 48 14 67 8 10 23 19 36 22 8 2 24 2 32 0 23-6 43-25 50-46 7-24 3-47-12-66a71 71 0 00-72-23l-6 1-8-7A569 569 0 00599 30z"></path>
      <path d="M540 359l-51 133-81 210-49 126 63 1h63l3-10 17-44 12-34h95l94-1-25-70-9-25-62-1h-63l41-112c24-68 41-112 41-111l36 103 72 203 36 102h125l-18-48a70712 70712 0 01-112-289l-39-102-29-76-2-5H559z"></path>
    </svg>
  )
}

export default ApolloIcon
