import React from "react"

function JavascriptIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 256"
    >
      <path fill="#F7DF1E" d="M0 0h256v256H0V0z"></path>
      <path d="M67 214l20-12c4 7 7 12 15 12s13-3 13-15v-81h24v82c0 25-14 36-36 36-19 0-30-10-36-22m85-3l20-11c5 8 12 15 24 15 10 0 16-5 16-12 0-8-7-11-18-16l-6-3c-17-7-28-16-28-36 0-18 13-32 35-32 15 0 26 5 34 19l-19 12c-4-7-8-10-15-10s-12 5-12 10c0 8 5 11 15 15l6 3c21 8 32 17 32 37 0 22-17 34-40 34-22 0-37-11-44-25"></path>
    </svg>
  )
}

export default JavascriptIcon
