import React from "react"

function ReduxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 244"
      {...props}
    >
      <path
        fill="#764ABC"
        d="M177 170c10-1 17-9 17-19-1-10-9-18-19-18a18 18 0 00-13 31c-11 22-28 38-53 51-17 9-35 13-53 10-15-2-26-8-33-19a48 48 0 01-3-50c6-13 16-22 22-26l-4-17c-47 34-42 81-28 102 11 17 33 27 57 27 6 0 13-1 19-3 42-8 73-32 91-69zm58-41c-25-29-62-45-103-45h-5c-3-5-10-9-16-9h-1a18 18 0 001 36c7 0 14-5 16-11h6c25 0 48 7 69 21 17 11 28 25 35 42 6 14 5 27-1 38-9 18-24 27-44 27-13 0-26-4-32-7l-15 12c14 6 28 10 42 10 31 0 55-17 63-34 10-19 9-52-15-80zM69 175c1 10 9 18 18 18h1a18 18 0 00-1-37l-2 1c-14-22-19-47-17-73 1-19 7-36 19-50 9-12 28-18 40-18 34-1 49 42 50 59l16 5c-4-53-36-80-67-80-30 0-57 21-68 52-15 42-5 82 14 114-2 2-3 6-3 9z"
      ></path>
    </svg>
  )
}

export default ReduxIcon
