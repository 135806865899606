import React from "react"

function ElectronIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <g fill="none" fillRule="evenodd">
        <circle cx="128" cy="128" r="128" fill="#2B2E3A"></circle>
        <g fill="#9FEAF9" fillRule="nonzero">
          <path d="M101 72c-27-5-47 0-55 14-6 11-5 25 4 40a3 3 0 105-3c-8-13-10-26-5-34 7-12 26-16 50-12a3 3 0 001-5zm-37 72c10 12 24 22 39 31 38 22 78 28 98 14a3 3 0 10-3-4c-18 12-56 7-92-14-15-9-28-19-39-31a3 3 0 10-3 4z"></path>
          <path d="M195 141c17-20 23-41 14-55-6-10-18-16-35-16a3 3 0 000 5c15 1 26 5 31 14 7 12 1 30-14 48a3 3 0 104 4zm-44-68c-16 3-32 9-48 18-39 23-64 56-60 80a3 3 0 105-1c-3-21 21-52 58-74 15-9 31-15 46-18a3 3 0 10-1-5z"></path>
          <path d="M88 188c9 25 23 40 40 40 12 0 23-8 31-23a3 3 0 10-4-2c-8 13-18 20-27 20-14 0-27-14-35-37a3 3 0 10-5 2zm81-5c5-15 7-32 7-50 0-44-15-82-37-92a3 3 0 00-2 5c19 9 34 45 34 87 0 18-3 34-7 48a3 3 0 105 2zm50-3a13 13 0 10-25 0 13 13 0 0025 0zm-5 0a7 7 0 11-15 0 7 7 0 0115 0zM48 193a13 13 0 100-25 13 13 0 000 25zm0-5a7 7 0 110-15 7 7 0 010 15z"></path>
          <path d="M128 54a13 13 0 100-25 13 13 0 000 25zm0-5a7 7 0 110-15 7 7 0 010 15zm2 93c-5 2-10-2-11-7a9 9 0 017-11 9 9 0 014 18z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ElectronIcon
